<template>
  <div id="app">
    <Navigation />
    <router-view />
  </div>
</template>

<script>
import Table from "./views/Table.vue";
import Navigation from "./components/Navigation";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "App",
  components: {
    Table,
    Navigation,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  background: linear-gradient(144deg, #9ed2ca 0%, #9ed2ca 27%, #dbeee5 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#app {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
</style>
