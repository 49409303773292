import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "GR-24-GRAPH",
    component: () => import("../views/GraphsGR24.vue"),
  },
  {
    path: "/nr-graph",
    name: "NR-GRAPH",
    component: () => import("../views/GraphsNR.vue"),
  },
  {
    path: "/gr-24-table",
    name: "GR-24-TABLE",
    component: () => import("../views/TableGR24.vue"),
  },
  {
    path: "/gr-graph",
    name: "GR-GRAPH",
    component: () => import("../views/Graphs.vue"),
  },
  {
    path: "/gr-table",
    name: "GR-TABLE",
    component: () => import("../views/Table.vue"),
  },
  {
    path: "/bgr-graph",
    name: "BGR-GRAPH",
    component: () => import("../views/GraphsBGR.vue"),
  },
  {
    path: "/bgr-table",
    name: "BGR-TABLE",
    component: () => import("../views/TableBGR.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
